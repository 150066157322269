<template>
  <div>
    <b-skeleton-wrapper :loading="thumbLoading">
      <template #loading>
        <b-skeleton-img class="thumb" aspect="1:1"></b-skeleton-img>
      </template>

      <img
        class="img-fluid"
        :class="rowData.thumbnail ? 'thumb' : 'default-thumb'"
        :src="rowData.thumbnail"
        title="Playlist Thumbnail"
        width="120"
        height="120"
        @error="setAltThumbnail"
      />
    </b-skeleton-wrapper>

    <!-- Hidden elem to determine if image is loaded -->
    <img
      class="hidden"
      :class="rowData.thumbnail ? 'thumb' : 'default-thumb'"
      :src="rowData.thumbnail"
      alt="Playlist Thumbnail"
      width="120"
      height="120"
      @error="setAltThumbnail"
      @load="onThumbLoad"
    />
  </div>
</template>

<script>
  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    data() {
      return {
        thumbLoading : true,
      }
    },
    watch : {
      rowData(val) {
        if (val)
          this.thumbLoading = true;
      },
    },
    methods : {

      /**
       * Set Alternative Thumbnail
       * @param event
       */
      setAltThumbnail(event) {
        event.target.src =
          require('../../../assets/images/music-album-placeholder.png');
      },

      /**
       * On Thumbnail Load
       */
      onThumbLoad() {
        this.thumbLoading = false;
      },
    },
    mounted() {
      this.thumbLoading = true;
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .hidden {
    visibility: hidden;
    position: absolute;
    display: inline-block;
  }

  .default-thumb {
    background-color: rgba(0,0,0,0.3);
  }
</style>

